<template>
    <app-layout @asideClosed="project.selected = null" @modalClosed="modal.opened = false">
        <loader v-if="loading" absolute />

        <card v-else>
            <template #icon>
                <clip-icon />
            </template>

            <template #title>{{ $t('projects.title') }}</template>

            <template #controls>
                <text-button size="small medium-lg" @click.stop="modal.opened = true">
                    {{ $t('projects.button') }}
                    <plus-circle-icon />
                </text-button>
            </template>

            <loader v-if="project.loading" />

            <table-component v-else>
                <template #head>
                    <tr>
                        <th>{{ $t('projects.table.name') }}</th>
                        <th>{{ $t('projects.table.date') }}</th>
                        <th>{{ $t('projects.table.link') }}</th>
                        <th>{{ $t('projects.table.balance') }}</th>
                        <th></th>
                    </tr>
                </template>

                <template #body>
                    <tr v-for="(item, index) in project.items" :key="index">
                        <table-cell>
                            <template #heading>{{ $t('projects.table.name') }}</template>

                            <template #data>
                                <router-link :to="{ name: 'projects.statistics', params: { id: item.id } }">
                                    {{ item.website }}
                                </router-link>
                            </template>
                        </table-cell>

                        <table-cell>
                            <template #heading>{{ $t('projects.table.date') }}</template>

                            <template #data>{{ projectDate(item) }}</template>
                        </table-cell>

                        <table-cell>
                            <template #heading>{{ $t('projects.table.link') }}</template>

                            <template #data>
                                <span>{{ item.website }}</span>

                                <a :href="`https://${item.website}`" target="_blank">
                                    <LinkOutside />
                                </a>
                            </template>
                        </table-cell>

                        <table-cell theme="success">
                            <template #heading>{{ $t('projects.table.balance') }}</template>

                            <template #data>{{ projectBalance(item) }}</template>
                        </table-cell>

                        <table-cell :stretch="false">
                            <template #data>
                                <text-button size="small" theme="secondary" @click.stop.prevent="project.selected = index">
                                    {{ $t('projects.table.details') }}
                                    <info-circle-icon />
                                </text-button>
                            </template>
                        </table-cell>
                    </tr>
                </template>
            </table-component>
        </card>

        <template #modal>
            <modal v-if="modal.opened" :title="$t('projects.modal.title')" @close="modal.opened = false">
                <validation-observer ref="observer" v-slot="{ handleSubmit }" slim>
                    <form @submit.prevent="handleSubmit(submitProjectCreate)">
                        <validation-provider vid="url" name="project_url" rules="required|url" v-slot="{ errors, reset }" slim>
                            <input-group :errors="errors" :label="$t('projects.modal.url.label')" :description="$t('projects.modal.url.description')">
                                <input-component
                                    v-model="modal.website"
                                    :placeholder="$t('projects.modal.url.placeholder')"
                                    @input="reset"
                                    @mousedown.stop
                                />
                            </input-group>
                        </validation-provider>

                        <text-button :loading="modal.loading" full>{{ $t('projects.modal.button') }}</text-button>
                    </form>
                </validation-observer>
            </modal>
        </template>

        <template #aside>
            <settings
                v-if="null !== project.selected"
                :games="steamApps"
                :project="project.items[project.selected]"
                @close="project.selected = null"
                @update="updateSelectedProject"
            />
        </template>
    </app-layout>
</template>

<script>
    import { DateTime } from 'luxon';
    import { ValidationObserver, ValidationProvider } from 'vee-validate';
    import { CURRENCY_USD } from '@/constants/currencies';
    import { formatPrice } from '@/helpers/common';
    import { projectCreate, projects, steamApps } from '@/services/skinsPaymentApi';
    import responseProcessingMixin from '@/mixins/responseProcessingMixin';
    import AppLayout from '@/pages/layout/AppLayout';
    import CalendarCheckIcon from '@/components/icons/CalendarCheckIcon';
    import CalendarIcon from '@/components/icons/CalendarIcon';
    import Card from '@/components/Card';
    import ClipIcon from '@/components/icons/ClipIcon';
    import Indicator from '@/components/Indicator';
    import InfoCircleIcon from '@/components/icons/InfoCircleIcon';
    import InputComponent from '@/components/inputs/InputComponent';
    import InputGroup from '@/components/inputs/InputGroup';
    import LinkOutside from '@/components/icons/LinkOutside';
    import Loader from '@/components/Loader';
    import Modal from '@/components/Modal';
    import Pagination from '@/components/Pagination';
    import PlusCircleIcon from '@/components/icons/PlusCircleIcon';
    import Settings from '@/components/Settings';
    import TableCell from '@/components/tables/TableCell';
    import TableComponent from '@/components/tables/TableComponent';
    import TextButton from '@/components/buttons/TextButton';
    import Toast from '@/components/Toast';
    import WalletIcon from '@/components/icons/WalletIcon';
    import WithdrawIcon from '@/components/icons/WithdrawIcon';

    export default {
        name: 'Projects',
        components: {
            AppLayout,
            CalendarCheckIcon,
            CalendarIcon,
            Card,
            ClipIcon,
            Indicator,
            InfoCircleIcon,
            InputComponent,
            InputGroup,
            LinkOutside,
            Loader,
            Modal,
            Pagination,
            PlusCircleIcon,
            Settings,
            TableCell,
            TableComponent,
            TextButton,
            ValidationObserver,
            ValidationProvider,
            WalletIcon,
            WithdrawIcon,
        },
        mixins: [responseProcessingMixin],
        methods: {
            projectDate: project => DateTime.fromISO(project.created_at).toFormat('dd.MM.y, HH:mm'),
            projectBalance: project => formatPrice(project.balance, CURRENCY_USD),
            fetchSteamApps() {
                this.loading = true;

                return steamApps()
                    .then(({ data }) => (this.steamApps = data))
                    .catch(() =>
                        this.$toast({
                            component: Toast,
                            props: {
                                type: 'error',
                                message: this.$t('projects.errors.apps'),
                            },
                        })
                    )
                    .finally(() => (this.loading = false));
            },
            fetchProjects() {
                this.project.loading = true;

                return projects()
                    .then(({ data }) => (this.project.items = data.data))
                    .catch(() =>
                        this.$toast({
                            component: Toast,
                            props: {
                                type: 'error',
                                message: this.$t('projects.errors.list'),
                            },
                        })
                    )
                    .finally(() => (this.project.loading = false));
            },
            submitProjectCreate() {
                this.modal.loading = true;

                projectCreate(this.modal.website)
                    .then(() => {
                        this.modal.opened = false;
                        this.modal.website = null;

                        this.$refs.observer.reset();

                        this.$toast({
                            component: Toast,
                            props: {
                                type: 'success',
                                message: this.$t('projects.success'),
                            },
                        });
                    })
                    .catch(({ response }) => this.processErrorResponse(response, this.$refs.observer))
                    .finally(() => (this.modal.loading = false));
            },
            updateSelectedProject(data) {
                const index = this.project.items.findIndex(project => project.id === data.id);

                this.project.selected = index;
                this.project.items[index] = data;
            },
        },
        data() {
            return {
                loading: true,
                modal: {
                    loading: false,
                    opened: false,
                    website: null,
                },
                project: {
                    items: [],
                    loading: true,
                    selected: null,
                },
                steamApps: [],
            };
        },
        mounted() {
            this.fetchSteamApps();
            this.fetchProjects();
        },
    };
</script>

<style lang="scss" scoped>
    .table-cell:nth-child(3) {
        a {
            display: inline-flex;
            width: 1.2rem;
            height: 1.2rem;
            margin-left: 0.5rem;

            &:hover {
                opacity: 0.75;
            }
        }

        svg {
            fill: #555d75;
        }
    }

    .table-cell:last-child .button {
        height: 2.8rem;
    }

    @media screen and (min-width: 75em) {
        .table-cell:last-child .button {
            height: 3.2rem;
        }
    }
</style>

<template>
    <scroll-container>
        <div class="settings">
            <div v-if="project" class="settings__project-controls">
                <p>{{ $t('projects.settings.project') }}</p>
                <h1>{{ project.website }}</h1>
            </div>

            <div class="settings__header">
                <div>
                    <h1>{{ $t('projects.settings.title') }}</h1>
                    <p>ID: {{ project.id }}</p>
                </div>

                <button type="button" @click="$emit('close')">
                    <close-icon />
                </button>
            </div>

            <validation-observer ref="observer" v-slot="{ handleSubmit }" slim>
                <form class="settings__body" @submit.prevent="handleSubmit(submit)">
                    <validation-provider name="postback_url" rules="required|url" v-slot="{ errors, reset }" slim>
                        <input-group
                            :errors="errors"
                            :label="$t('projects.settings.form.postback_url.label')"
                            :description="$t('projects.settings.form.postback_url.description')"
                        >
                            <input-component
                                v-model="form.postback_url"
                                :placeholder="$t('projects.settings.form.postback_url.placeholder')"
                                @input="reset"
                                @mousedown.stop
                            />
                        </input-group>
                    </validation-provider>

                    <validation-provider name="success_url" rules="required|url" v-slot="{ errors, reset }" slim>
                        <input-group
                            :errors="errors"
                            :label="$t('projects.settings.form.success_url.label')"
                            :description="$t('projects.settings.form.postback_url.description')"
                        >
                            <input-component
                                v-model="form.success_url"
                                :placeholder="$t('projects.settings.form.success_url.placeholder')"
                                @input="reset"
                                @mousedown.stop
                            />
                        </input-group>
                    </validation-provider>

                    <validation-provider name="fail_url" rules="required|url" v-slot="{ errors, reset }" slim>
                        <input-group
                            :errors="errors"
                            :label="$t('projects.settings.form.fail_url.label')"
                            :description="$t('projects.settings.form.fail_url.description')"
                        >
                            <input-component
                                v-model="form.fail_url"
                                :placeholder="$t('projects.settings.form.fail_url.placeholder')"
                                @input="reset"
                                @mousedown.stop
                            />
                        </input-group>
                    </validation-provider>

                    <validation-provider name="secret_key" rules="required" v-slot="{ errors, reset }" slim>
                        <input-group
                            :errors="errors"
                            :label="$t('projects.settings.form.secret_key.label')"
                            :description="$t('projects.settings.form.secret_key.description')"
                        >
                            <input-component
                                v-model="form.secret_key"
                                :placeholder="$t('projects.settings.form.secret_key.placeholder')"
                                @input="reset"
                                @mousedown.stop
                            />
                        </input-group>
                    </validation-provider>

                    <div class="settings__input-group-row">
                        <validation-provider name="priority_app_id" rules="required" v-slot="{ errors, reset }" slim>
                            <input-group
                                :errors="errors"
                                :label="$t('projects.settings.form.priority_app.label')"
                                :description="$t('projects.settings.form.priority_app.description')"
                            >
                                <select-component
                                    v-model="form.priority_app_id"
                                    class="settings__games"
                                    :options="gameOptions"
                                    :placeholder="$t('projects.settings.form.priority_app.placeholder')"
                                    @input="reset"
                                />
                            </input-group>
                        </validation-provider>

                        <validation-provider name="deposit_multiplier" rules="required|min_value:1|max_value:2.99" v-slot="{ errors, reset }" slim>
                            <input-group
                                :errors="errors"
                                :label="$t('projects.settings.form.deposit_multiplier.label')"
                                :description="$t('projects.settings.form.deposit_multiplier.description')"
                            >
                                <input-component
                                    v-model="form.deposit_multiplier"
                                    :hint="$t('projects.settings.form.deposit_multiplier.hint')"
                                    :placeholder="$t('projects.settings.form.deposit_multiplier.placeholder')"
                                    @input="reset"
                                    @mousedown.stop
                                />
                            </input-group>
                        </validation-provider>
                    </div>

                    <text-button :loading="loading" full>{{ $t('projects.settings.button') }}</text-button>
                </form>
            </validation-observer>
        </div>
    </scroll-container>
</template>

<script>
    import { ValidationObserver, ValidationProvider } from 'vee-validate';
    import { projectUpdate } from '@/services/skinsPaymentApi';
    import responseProcessingMixin from '@/mixins/responseProcessingMixin';
    import CloseIcon from '@/components/icons/CloseIcon';
    import InputComponent from '@/components/inputs/InputComponent';
    import InputGroup from '@/components/inputs/InputGroup';
    import ScrollContainer from '@/components/ScrollContainer';
    import SelectComponent from '@/components/inputs/SelectComponent';
    import TextButton from '@/components/buttons/TextButton';
    import Toast from '@/components/Toast';

    export default {
        name: 'ProjectSettings',
        components: {
            CloseIcon,
            InputComponent,
            InputGroup,
            ScrollContainer,
            SelectComponent,
            TextButton,
            ValidationObserver,
            ValidationProvider,
        },
        mixins: [responseProcessingMixin],
        props: {
            games: {
                type: Array,
                required: true,
            },
            project: {
                type: Object,
                required: true,
            },
        },
        methods: {
            submit() {
                this.loading = true;

                projectUpdate(this.project.id, this.form)
                    .then(({ data }) => {
                        this.$emit('update', data);

                        this.$toast({
                            component: Toast,
                            props: {
                                type: 'success',
                                message: this.$t('projects.settings.success'),
                            },
                        });
                    })
                    .catch(({ response }) => this.processErrorResponse(response, this.$refs.observer))
                    .finally(() => (this.loading = false));
            },
        },
        watch: {
            'form.postback_url': function (value) {
                this.form.postback_url = value.toLowerCase();
            },
            'form.success_url': function (value) {
                this.form.success_url = value.toLowerCase();
            },
            'form.fail_url': function (value) {
                this.form.fail_url = value.toLowerCase();
            },
        },
        data() {
            return {
                form: {
                    postback_url: this.project.postback_url,
                    success_url: this.project.success_url,
                    fail_url: this.project.fail_url,
                    secret_key: this.project.secret_key,
                    deposit_multiplier: this.project.deposit_multiplier,
                    priority_app_id: this.project.priority_app_id,
                },
                gameOptions: this.games.map(game => ({ image: game.logo, value: game.id })),
                loading: false,
            };
        },
    };
</script>

<style lang="scss" scoped>
    .settings {
        flex-grow: 1;
    }

    .settings__project-controls {
        padding: 1.5rem;

        p {
            margin-bottom: 0.5rem;
            color: #5f728d;
            font-family: 'Gotham Book', sans-serif;
            font-size: 1.1rem;
            font-weight: 325;
            text-transform: uppercase;
        }

        h1 {
            color: #e5ecf9;
            font-family: 'Gotham Black', sans-serif;
            font-size: 1.2rem;
            font-weight: 900;
            text-transform: uppercase;
        }
    }

    .settings__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.5rem;
        background-color: #161a26;

        h1 {
            margin-bottom: 0.5rem;
            color: #e5ecf9;
            font-family: 'Gotham Book', sans-serif;
            font-size: 1.6rem;
            font-weight: 400;
        }

        p {
            color: #73befa;
            font-family: 'Gotham Medium', sans-serif;
            font-size: 1.2rem;
            font-weight: 350;
        }

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 3rem;
            height: 3rem;
            border-radius: 0.8rem;
            background-color: #1c2231;
            transition: background-color 0.3s;

            &:hover {
                background-color: #252d3f;

                svg {
                    fill: #9199b1;
                }
            }

            svg {
                width: 1.6rem;
                fill: #555d75;
                transition: fill 0.3s;
            }
        }
    }

    .settings__body {
        padding: 1.5rem;
    }

    .settings__input-group-row {
        display: flex;
        justify-content: space-between;

        > .input-group {
            width: calc(50% - 0.6rem);
            flex-shrink: 0;
        }
    }

    .scroll-container {
        overflow: visible;
    }

    ::v-deep {
        .scroll-container__content {
            position: relative;
            z-index: 1;
        }

        .scroll-container__vertical-scrollbar {
            z-index: 1;
        }

        .settings__games .select__option-image,
        .settings__games .select__value-image {
            display: flex;
            height: 1.4rem;
            fill: #fefefe;
        }

        .input__input {
            background-color: #161c28;
        }
    }

    @media screen and (min-width: 120em) {
        .settings__project-controls {
            padding: 3rem 4rem;

            p {
                font-size: 1.4rem;
            }

            h1 {
                margin-top: 0.5rem;
                font-size: 2.4rem;
            }
        }

        .settings__header {
            padding: 2.2rem 4rem;

            h1 {
                font-size: 2.4rem;
            }

            p {
                font-size: 1.4rem;
            }
        }

        .settings__body {
            padding: 2.2rem 4rem 4rem;
        }

        ::v-deep {
            .scroll-container__vertical-scrollbar {
                right: -0.3rem;
                z-index: 0;
            }

            .settings__games .select__option-image,
            .settings__games .select__value-image {
                height: 1.8rem;
            }
        }
    }
</style>
